<script lang="ts" setup>
import { useMotions } from '@vueuse/motion'
import { useDebounceFn } from '@vueuse/core'
import type { SelectItem } from '~~/types/select'

const props = defineProps<{
    label?: string
    modelValue?: any
    disabled?: boolean
    type?: string
    items: SelectItem[]
    placeholder?: string
    error?: string | false
    onQuery?: (query: string, items: SelectItem[]) => Promise<SelectItem[]>
    onAdd?: (query: string) => any
}>()
const emit = defineEmits(['update:modelValue', 'add', 'selected', 'clear'])
const motions = useMotions()
const { fuzzyMatch } = useSearch()

const isFocused = ref(false)
const searchQuery = ref('')
const currentItems = ref([])
const isLoading = ref(false)
const hasSelectedItem = ref(false)
const selectedItemImage = ref('')

const onFocus = () => {
    if (props.disabled) {
        return
    }
    isFocused.value = true
    hasSelectedItem.value = false
}
const onBlur = () => isFocused.value = false

const updateItems = useDebounceFn(async (callback: (query: string, items: SelectItem[]) => Promise<SelectItem[]>) => {
    isLoading.value = true
    const newItems = await callback(searchQuery.value, props.items)
    isLoading.value = false
    const filteredNewItems = newItems.filter(item =>
        'label' in item
        && 'value' in item,
    )
    currentItems.value = searchQuery.value ? filteredNewItems : props.items
}, 300)

const onInput = async () => {
    if (props.onQuery) {
        await updateItems(props.onQuery)
    }
    hasSelectedItem.value = false
}

const onToggle = async () => {
    if (props.disabled) {
        return
    }
    isFocused.value = !isFocused.value
    hasSelectedItem.value = false
}

const onClear = () => {
    if (props.disabled) {
        return
    }
    emit('update:modelValue', null)
    emit('clear')
    searchQuery.value = ''
    currentItems.value = props.items
    selectedItemImage.value = ''
}

const onItemClick = (item: SelectItem) => {
    searchQuery.value = item.label
    hasSelectedItem.value = true
    isFocused.value = false
    if (item.image) {
        selectedItemImage.value = item.image
    }
    emit('update:modelValue', item.value)
    emit('selected', item)
}

const filteredItems = computed(() =>
    currentItems.value.filter(item => fuzzyMatch(item.label, searchQuery.value)),
)

const showAddOption = computed(() => {
    if (props.onAdd && searchQuery.value) {
        return !filteredItems.value.some(item => item.label.toLowerCase().trim() === searchQuery.value.toLowerCase().trim())
    }
    return false
})

watchEffect(() => {
    if (!searchQuery.value) {
        currentItems.value = props.items
        selectedItemImage.value = ''
    }
})

watchEffect(() => {
    if (props.items) {
        currentItems.value = props.items
    }
})

watch([() => props.modelValue], ([newValue]) => {
    if (newValue) {
        const item = props.items.find(item => item.value === newValue)
        if (item) {
            searchQuery.value = item.label
            hasSelectedItem.value = true
            if (item.image) {
                selectedItemImage.value = item.image
            }
        }
        else {
            onClear()
        }
    }
    else {
        onClear()
    }
})
onMounted(() => {
    if (props.modelValue) {
        searchQuery.value = props.items.find(item => item.value === props.modelValue)?.label ?? ''
    }
})
</script>

<template>
    <div relative :cursor-not-allowed="disabled">
        <div
            flex b-1 b-primary-100 rounded-2 relative mt-2 bg-white
            cursor-pointer items-center
            transition
            :class="[
                disabled ? '!cursor-not-allowed !b-textcolor-50' : '',
                isFocused ? '!b-primary-300' : '',
                error ? '!b-red' : '',
            ]"
        >
            <nuxt-img v-if="selectedItemImage" :src="selectedItemImage" h-5 w-5 rounded-2 mr-1 relative top-6px left-3/>
            <input
                v-model="searchQuery"
                :disabled="disabled"
                :class="[
                    disabled ? 'text-textcolor-300 cursor-pointer' : '',
                ]"
                type="text"
                class="peer" rounded-2
                w-full outline-none px-3 pb-2
                pt-5 text-textcolor-300 bg-transparent relative
                z-2
                :placeholder="placeholder"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
            >
            <div
                v-if="label"
                class="peer-focus:top-6px peer-focus:text-13px"
                :class="[
                    disabled ? 'text-textcolor-300' : '',
                    modelValue !== undefined || Boolean(placeholder) ? '!top-6px !text-13px' : '',
                ]"
                absolute text-black top-14px left-3
                text-primary-300
                z-0
                font-bold transition-all
            >
                {{ label }}
            </div>
            <div
                v-if="searchQuery" p-2px rounded-full bg-gray-100
                transition mr-3
                @click="onClear"
            >
                <div text-gray i-material-symbols-close />
            </div>
            <div
                p-2px rounded-full bg-primary-100 transition
                mr-3
                @click="onToggle"
            >
                <div v-if="isLoading" text-secondary i-eos-icons-loading />
                <div v-else text-primary-300 i-material-symbols-arrow-drop-down />
            </div>
        </div>
        <div v-if="error" text-red text-12px>
            {{ error }}
        </div>
        <transition
            :css="false"
            @leave="(_, done) => motions.itemList.leave(done)"
        >
            <div
                v-if="isFocused && !hasSelectedItem"
                v-motion="'itemList'"
                :initial="{ maxHeight: 0, opacity: 0 }"
                :enter="{ maxHeight: 128, opacity: 1 }"
                :leave="{ maxHeight: 0, opacity: 0 }"
                :class="[
                    isFocused ? '!b-primary-300' : '',
                    error ? '!b-red' : '',
                ]"
                class="top-12"
                text-left bg-white b-x-1 b-b-1
                b-primary-100 absolute left-0 right-0 max-h-32
                box-border overflow-y-scroll
                rounded-b-2 z-5
            >
                <div
                    v-if="filteredItems.length > 0 || onAdd"
                >
                    <cc-select-item
                        v-for="item in filteredItems"
                        :key="item.value"
                        :image="item.image"
                        @click="() => onItemClick(item)"
                    >
                        {{ item.label }}
                    </cc-select-item>
                    <div v-if="showAddOption">
                        <cc-select-item
                            flex items-center
                            bg-primary-100 text-textcolor-900 font-bold
                            class="hover:bg-primary-100/50"
                            @click="() => onAdd(searchQuery)"
                        >
                            <div i-carbon-add mr-1 />
                            Adicionar "{{ searchQuery }}"
                        </cc-select-item>
                    </div>
                </div>
                <div v-else vertical-center text-textcolor-100 text-3 py-2>
                    <div i-carbon-non-certified text-4 />
                    Nenhum item encontrado.
                </div>
            </div>
        </transition>
    </div>
</template>
