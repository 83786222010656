<script lang="ts" setup>
const props = defineProps<{
    message: string
    description?: string
    warning?: boolean
}>()

const iconUrl = computed(() => `/icons/notification-${props.warning ? 'warning' : 'success'}.svg`)
</script>

<template>
    <div
        b-2 b-primary-100 max-w-300px
        bg-white flex items-center justify-center
        py-1 rounded-xl px-3 mx-auto z-9999
        shadow-md shadow="backgrounddark/40"
        font-sans
        :class="[
            {[`
                !bg-white/90 !b-secondary-100
            `]: props.warning },
            {[`
                !justify-start
            `]: props.description },
        ]"
    >
        <nuxt-img :src="iconUrl" w-40px relative top-2px mr-2/>
        <div text-center :class="[{[`!text-left`]: description}]">
            <div
                text-primary font-700 text-13px
                :class="[{[`
                    !text-secondary
                `]: props.warning }, 'notification-title']"
            >
                {{ message }}
            </div>
            <div
                v-if="description" text-12px
                text-textcolor-500
            >
                {{ description }}
            </div>
        </div>
    </div>
</template>
