import { POSITION, useToast } from 'vue-toastification'
import notification from '../components/notification.vue'

export const useNotification = () => {
    const toast = useToast()

    const notify = (opts: { message: string; description?: string; extraProps?: Record<string, any> }) => toast({
        component: notification,
        props: {
            message: opts.message,
            description: opts.description,
            ...opts.extraProps,
        },
    }, {
        position: POSITION.BOTTOM_CENTER,
        timeout: 2000,
        icon: false,
        closeButton: false,
        bodyClassName: 'notification-body',
        hideProgressBar: true,
    })

    const success = (message: string, description?: string) => notify({ message, description })
    const warning = (message: string, description?: string) => notify({ message, description, extraProps: { warning: true } })

    return {
        success,
        warning,
    }
}
